<template>
  <div class="height_100">
    <el-container class="height_100">
      <el-aside class="left" style="background-color: #2e3751">
        <el-menu
          :default-active="activeIndex"
          @open="handleOpen"
          @close="handleClose"
          @select="handleSelect"
          class="el-menu-vertical-demo"
          background-color="#2e3751"
          text-color="#fff"
          active-text-color="#ff6618"
        >
          <el-menu-item class="menu-item" @click="goHome">
            <span slot="title">网站首页</span>
          </el-menu-item>

          <el-menu-item class="menu-item" @click="go('appeal')" index="1">
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">我发布的诉求</span>
          </el-menu-item>
          <!-- <el-menu-item class="menu-item" @click="enterpriseorder()" index="2">
            <span slot="title " style="font-size: 18px">我的订单</span>
          </el-menu-item> -->
          <el-menu-item class="menu-item" @click="go('enterpriseorder')" index="2">
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">我的订单</span>
          </el-menu-item>
          <el-menu-item
            class="menu-item"
            @click="go('enterprisedeclare')"
            index="3"
          >
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">我的申报</span>
          </el-menu-item>
          <el-menu-item
            index="4"
            class="menu-item"
            @click="go('enterpriseaccount')"
            v-if="Isparent === true"
          >
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">企业子账号管理</span>
          </el-menu-item>
          <el-menu-item
            index="5"
            class="menu-item"
            @click="go('marketManagement')"
          >
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">供方市场管理</span>
          </el-menu-item>
          <el-menu-item
            index="6"
            class="menu-item"
            @click="go('purchaseManagement')"
          >
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">需方市场管理</span>
          </el-menu-item>
          <el-menu-item
            index="7"
            class="menu-item"
            @click="go('honor')"
          >
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">企业荣誉</span>
          </el-menu-item> 
          <el-menu-item
            index="8"
            class="menu-item"
            @click="go('dynamic')"
          >
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">企业动态</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header
          style="
            height: 72px;
            line-height: 72px;
            border-bottom: 1px solid #e6e6e6;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #2e3751;
            color: #fff;
            font-size: 20px;
          "
        >
        青海省中小企业公共服务平台-企业个人中心

          <div style="display: flex; align-items: center">
            <i
              class="el-icon-user-solid"
              style="color: #fff; font-size: 20px"
            ></i>
            <div style="padding: 0 12px; color: #fff; font-size: 20px">
              {{ userphone }}
              <span style="margin-left: 10px">个人中心</span>
            </div>
            <div @click="login">退出</div>
          </div>
        </el-header>
        <el-main>
          <router-view v-if="this.$route.path != '/personalcenter'" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>


<script>
export default {
  data() {
    return {
      userphone: localStorage.phone,
      Isparent: false,
      activeIndex: "1",
    };
  },
  created() {
    // this.appeal();
    this.userlist();
    this.mechanism_status();
  },
  methods: {
    userlist() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("user_lst_d", data).then((res) => {
        console.log(res, " res");
        this.Isparent = res.result.is_parent;
      });
    },
    appeal() {
      this.$router.push("/appeal");
    },
    go(url){
      console.log(url,'url')
      this.$router.push(`/${url}`);
    },
    handleSelect(key, keyPath) {
      console.log(key, "keyhandleSelect");
      console.log(keyPath, "keyPathhandleSelect");
      if (key == "1") {
        this.$router.push("/information");
      } else if (key == "2") {
        this.$router.push("/serviceAction");
      } else if (key == "3") {
        this.$router.push("/nextAction");
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath, "handleOpen");
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath, "handleClose");
    },

    goHome() {
      this.$router.push("/");
    },

    mechanism_status() {
      let data = {
        token: localStorage.eleToken,
      };

      this.$post("/select_enterprise_status", data).then((res) => {
        console.log(res, "res");

        if (res.result === 2) {
          this.appeal();
        } else if (res.result === 0 || res.result === 3) {
          // location.href = "http://sso.zhenchangkj.com:9000/realname/index";

          this.$confirm("您的身份还未认领, 是否认领?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              setTimeout(() => {
                location.href = "https://sso.smeqh.cn/realname/index";
              }, 2000);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
              this.appeal();
            });
        } else if (res.result === 1) {
          this.$message({
            type: "info",
            message: "您的企业正在认领中，审核通过之后才能进行下一步操作",
          });
        }
      });
    },

    login() {
      this.$confirm("是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.clear();
          // location.reload();
          this.$message({
            type: "success",
            message: "已退出",
          });
          this.$router.push("/");
          // this.$get("/logout?token=" + this.token).then((res) => {
          //   localStorage.clear();
          //   location.reload();
          //   this.$message({
          //     type: "success",
          //     message: "已退出",
          //   });
          // });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  padding-left: 20px;
  color: rgb(255, 255, 255);
  background: rgb(46, 55, 81);
  text-align: center;
  font-size: 20px;
  height: 70px;
  line-height: 70px;
}
.menu_class {
  height: 60px;
  line-height: 60px;
}
.el-menu {
  border: transparent;
}
.menu-item {
  background: #262e45;
  text-align: left;
  font-size: 20px;
  height: 70px;
  line-height: 70px;
}
// /deep/.el-menu-item.is-active {
//   height: 60px;
//   color: #fff !important;
//   background-color: #ff6618 !important;
// }
::v-deep.el-menu-item.is-active {
  height: 70px;
  color: #fff !important;
  line-height: 70px;
  background-color: #ff6618 !important;
}
::v-deep .el-submenu {
  text-align: left;
}
::v-deep.el-aside {
  width: 250px !important;
}
</style>
